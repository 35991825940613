@charset "UTF-8";
/* header and navbar */
header {
  background-color: #FFFFFF;
  border-bottom: 10px solid #00ba9e;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 0 5px;
}

.navbar-brand img {
  max-width: 200px;
}

@media screen and (max-width: 1000px) {
  .navbar-brand img {
    max-width: 160px;
  }
}

@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width: 140px;
  }
  .navbar {
    min-height: 60px;
  }
}

.navbar-nav > li > a {
  padding-top: 25px;
  color: #003777;
  text-transform: uppercase !important;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #003777;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px;
  font-family: "Lato", Arial, sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #00ba9e;
}

a:hover, a:active {
  color: #003777;
}

h1, h2, h3, h4, h5, h6 {
  color: #00ba9e;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #003777;
  border: 1px solid #003777;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.label {
  font-size: 100%;
  color: #00ba9e;
  padding: 0;
}

form p.control {
  margin-bottom: 0 !important;
}

main {
  padding: 30px 0 30px;
}

/* blog posts */
.post {
  padding: 0 0 20px;
  margin-bottom: 30px;
  border: 1px solid #ededed;
}

.post p {
  margin-bottom: 10px;
  padding: 10px;
}

.post h1, .post h2, .post h3, .post h4, .post h5, .post h6 {
  padding-left: 10px;
}

.meta {
  margin-top: 0;
  font-size: 13px;
  display: block;
  padding: 10px;
  background-color: #f9f9f9;
}

.meta i {
  color: #00ba9e;
}

.author, .date {
  padding: 0 10px 0 5px;
}

p.title {
  font-size: 24px;
  line-height: 37px;
  margin-bottom: 0;
  padding: 10px;
  color: #003777;
}

p.title a {
  color: #003777;
}

p strong {
  color: #003777;
  font-size: 15px;
}

.post-wrapper {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-box-orient:  horizontal;
  -webkit-box-direction:  normal;
}

.post-excerpt {
  padding: 0;
  flex-basis: 47%;
  margin: 0 1.5% 30px;
  display: inline-block;
  vertical-align: text-top;
  border: 1px solid #ededed;
}

.post-excerpt .thumbnail {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: 233px;
  overflow: hidden;
  border-radius: 0;
}

.post-excerpt a.button {
  font-weight: bold;
  color: #003777;
  border-bottom: 2px solid #003777;
  letter-spacing: .1em;
  display: block;
  padding: 10px;
}

.post-excerpt p.title {
  max-height: 80px;
  overflow: hidden;
}

.post-excerpt p {
  margin-bottom: 10px;
  padding: 10px;
  height: 130px;
  overflow: hidden;
}

.pagination {
  display: block;
  min-height: 50px;
  margin-top: 0;
}

.pagination a {
  text-transform: uppercase;
  color: #003777;
  border-bottom: 1px solid #00ba9e;
  letter-spacing: .1em;
}

.pagination a .right {
  display: inline-block;
  float: right;
}

.pagination a .left {
  display: inline-block;
  float: left;
}

.pagination a :hover {
  text-decoration: none;
  font-weight: bold;
}

/* sidebar */
aside h3.widget-title {
  font-size: 20px;
  line-height: 23px;
  padding: 10px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color: #003777;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid #ededed;
}

aside .textwidget {
  background-color: #00ba9e;
  padding: 20px;
  margin: -11px 0 20px;
}

aside .textwidget h4 {
  display: none;
}

aside .textwidget form {
  padding: 30px 20px 10px;
  border: 2px solid #003777;
}

aside .textwidget form input[type="submit"] {
  margin-bottom: 20px;
}

aside ul {
  margin-bottom: 30px;
}

aside ul li {
  flex-basis: 100% !important;
}

aside ul i.fa {
  width: 30px;
  text-align: center;
}

aside ul a {
  color: #003777;
}

/* footer */
.before-footer {
  background-color: #003777;
  color: #FFF;
  padding: 10px;
}

.before-footer h3 {
  color: #00ba9e;
  font-size: 18px;
}

.before-footer a {
  color: #FFF;
  display: block;
}

.before-footer a:hover {
  color: lightyellow;
}

footer {
  background-color: #003777;
  padding: 30px 0;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
}

footer .container {
  margin-left: auto;
  max-width: 100%;
}

footer a {
  display: none;
}

footer .copyright {
  padding-right: 10px;
  font-size: 12px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #00ba9e;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #003777 !important;
  padding: 10px 0 15px;
  text-align: left;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: #FFFFFF;
  font-weight: 700;
  max-width: 1140px;
  margin: 0 auto;
  text-transform: uppercase;
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  background-color: #003777;
  padding: 20px 0 30px;
  margin-top: -10px;
}

section#home-search h3 {
  color: #FFF;
  padding-top: 0;
  font-size: 2em;
  font-weight: 700;
}

section#home-search form {
  max-width: 75%;
  text-align: center;
  margin: auto;
  padding: 0;
}

section#home-search input[type="text"] {
  width: 37%;
}

section#home-search input[type="submit"] {
  background-color: #00ba9e;
  border: 1px solid #00ba9e;
  color: #FFF;
  display: inline-block;
  height: 45px;
}

@media screen and (max-width: 782px) {
  section#home-search form {
    max-width: 90%;
  }
  section#home-search form input[type="text"] {
    width: 100%;
  }
}

/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding: 10px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color: #003777;
  letter-spacing: 2px;
  text-transform: uppercase;
}

aside ul li {
  flex-basis: 100%;
  padding-bottom: 5px;
}

aside ul li a i {
  color: #00ba9e !important;
}

aside ul li a:hover {
  color: #00ba9e;
}

main .by-sector h3.jobs-title {
  background-color: #003777;
  color: #FFF;
  font-size: 16px;
  line-height: 23px;
  padding: 10px;
  text-transform: uppercase;
}

main .by-sector ul.sectors {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

main .by-sector ul.sectors li {
  padding: 5px 10px 5px 0;
  flex-basis: 32%;
}

@media screen and (max-width: 990px) {
  main .by-sector ul.sectors li {
    flex-basis: 48%;
  }
}

@media screen and (max-width: 430px) {
  main .by-sector ul.sectors li {
    flex-basis: 100%;
  }
}

main .by-sector ul.sectors li a {
  color: #003777;
}

main .by-sector ul.sectors li a i {
  width: 25px;
  color: #00ba9e;
  padding-right: 10px;
}

/*hero section*/
.hero {
  background-image: url("/images/lightbulb-bkg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 8% 0 10%;
}

.hero .hero_message {
  padding: 5%;
  background-color: rgba(255, 255, 255, 0.3);
}

.hero .hero_message h1 {
  color: #003777;
}

.hero .hero_message p {
  color: #111;
  font-size: 17px;
  line-height: 35px;
  letter-spacing: .03rem;
}

.hero .hero_message a {
  background-color: #00ba9e;
  color: #FFF;
}

/* cookie alert */
.alert {
  margin-bottom: 0;
}

.alert-success {
  background-color: white;
  border: none;
}

.alert-success strong {
  color: #003777;
}

.alert-success a {
  color: #00ba9e;
}
