$primary-color: #003777;
$secondary-color: #00ba9e;

$icon-color:#00ba9e;
$header-background:#FFFFFF;

$page-header-bkg:$primary-color;
$page-header:#FFFFFF;

$toggle-icon-bar:white;
$line-color:#ededed;

$home-search-bkg:$primary-color;
$home-search-text:#FFF;
$home-search-submit-bkg:$secondary-color;
$home-search-submit-txt:#FFF;


/* header and navbar */
header{
  background-color: $header-background;
  border-bottom: 10px solid $secondary-color;
}

.navbar{
  margin-bottom:0;
  min-height:70px;
}
.navbar-brand {
  padding: 0 5px;
  img {
    max-width:200px;
  }
}
@media screen and (max-width: 1000px){
  .navbar-brand img{
    max-width:160px;
  }
}
@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width:140px;
  }
  .navbar {
    min-height:60px;
  }
}
.navbar-nav>li>a {
  padding-top:25px;
  color: $primary-color;
  text-transform:uppercase !important;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$primary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px;
  font-family:"Lato", Arial, sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$secondary-color;
}
a:hover, a:active{
  color:$primary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.label{
  font-size:100%;
  color:$secondary-color;
  padding:0;
}
form{
  p.control{
    margin-bottom:0 !important;
  }
}
main {
  padding:30px 0 30px;
}

/* blog posts */
.post {
  padding: 0 0 20px;
  margin-bottom: 30px;
  border: 1px solid $line-color;
  p{
    margin-bottom:10px;
    padding:10px
  }
  h1, h2, h3, h4, h5, h6{
    padding-left:10px;
  }
}
.meta {
  margin-top:0;
  font-size:13px;
  display:block;
  padding:10px;
  background-color:#f9f9f9;
  i {
    color:$icon-color;
  }
}
.author, .date {
  padding:0 10px 0 5px;
}
p.title {
  font-size: 24px;
  line-height: 37px;
  margin-bottom: 0;
  padding: 10px;
  color: $primary-color;
  a {
    color: $primary-color;
  }
}
p strong{
  color:$primary-color;
  font-size:15px;
}
.post-wrapper{
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}
.post-excerpt{
  padding: 0;
  flex-basis:47%;
  margin: 0 1.5% 30px;
  display: inline-block;
  vertical-align: text-top;
  border: 1px solid $line-color;
  .thumbnail{
    margin-bottom:0;
    padding:0;
    border:none;
    height:233px;
    overflow: hidden;
    border-radius: 0;
  }
  a.button{
    font-weight: bold;
    color : $primary-color;
    border-bottom: 2px solid $primary-color;
    letter-spacing:.1em;
    display:block;
    padding:10px;
  }
  p.title{
    max-height: 80px;
    overflow: hidden;
  }
  p{
    margin-bottom:10px;
    padding:10px;
    height: 130px;
    overflow: hidden;
  }
}
.pagination {
  display: block;
  min-height: 50px;
  margin-top:0;
  a {
    text-transform: uppercase;
    color: $primary-color;
    border-bottom: 1px solid $secondary-color;
    letter-spacing: .1em;

    .right {
      display: inline-block;
      float: right;
    }
    .left {
      display: inline-block;
      float: left;
    }
    :hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
}
/* sidebar */
aside {
  h3.widget-title {
    font-size: 20px;
    line-height: 23px;
    padding:10px;
    font-weight: normal;
    margin-top: 0;
    color: white;
    background-color:$primary-color;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-bottom: 1px solid $line-color;
  }
  .textwidget{
    background-color:$secondary-color;
    padding:20px;
    margin:-11px 0 20px;
    h4{
      display:none;
    }
    form{
      padding:30px 20px 10px;
      border:2px solid $primary-color;
      input[type="submit"]{
        margin-bottom:20px;
      }
    }
  }
  ul{
    margin-bottom:30px;
    li{
      flex-basis:100% !important;
    }
    i.fa{
      width: 30px;
      text-align: center;
    }
    a{
      color:$primary-color;
    }
  }
}
/* footer */
.before-footer{
  background-color:$primary-color;
  color:#FFF;
  padding:10px;
  h3{
    color: $secondary-color;
    font-size:18px;
  }
  a{
    color:#FFF;
    display:block;
  }
  a:hover{
    color:lightyellow;
  }
}
footer{
  background-color:$primary-color;
  padding: 30px 0;
  color:#FFF;
  font-size: 14px;
  line-height: 21px;

  .container{
    margin-left: auto;
    max-width: 100%;
  }
  a {
    display: none;
  }
  .copyright {
    padding-right: 10px;
    font-size:12px;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  a {
    display:block;
    background-color: $secondary-color;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               : 10px 0 15px;
  text-align            : left;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    font-weight: 700;
    max-width:1140px;
    margin:0 auto;
    text-transform: uppercase;
  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background-color:$home-search-bkg;
  padding: 20px 0 30px;
  margin-top:-10px;
  h3{
    color:$home-search-text;
    padding-top: 0;
    font-size: 2em;
    font-weight: 700;
  }
  form{
    max-width: 75%;
    text-align: center;
    margin: auto;
    padding:0;
  }
  input[type="text"]{
    width:37%;
  }
  input[type="submit"]{
    background-color:$home-search-submit-bkg;
    border:1px solid $home-search-submit-bkg;
    color:$home-search-submit-txt;
    display:inline-block;
    height:45px;
  }
}
@media screen and (max-width:782px) {
  section#home-search form {
    max-width : 90%;
    input[type="text"]{
      width:100%;
    }
  }
}
/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding:10px;
  font-weight: normal;
  margin-top: 0;
  color: white;
  background-color:$primary-color;
  letter-spacing: 2px;
  text-transform: uppercase;
}
aside ul li {
  flex-basis: 100%;
  padding-bottom:5px;
  a i{
    color: $icon-color !important;
  }
  a:hover{
    color:$secondary-color;
  }
}
main .by-sector {
  h3.jobs-title{
    background-color:$primary-color;
    color:#FFF;
    font-size: 16px;
    line-height: 23px;
    padding: 10px;
    text-transform: uppercase;
  }
  ul.sectors {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: flex-start;
    li {
      padding: 5px 10px 5px 0;
      flex-basis: 32%;
      @media screen and (max-width:990px){
        flex-basis:48%;
      }
      @media screen and (max-width:430px) {
        flex-basis:100%;
      }
      a {
        color: $primary-color;
        i {
          width: 25px;
          color: $icon-color;
          padding-right: 10px;
        }
      }
    }
  }
}
/*hero section*/
.hero{
  background-image:url('/images/lightbulb-bkg.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  padding:8% 0 10%;
  .hero_message{
    padding:5%;
    background-color:rgba(255,255,255,.3);
    h1{
      color:$primary-color;
    }
    p{
      color:#111;
      font-size:17px;
      line-height:35px;
      letter-spacing: .03rem;
    }
    a{
      background-color:$secondary-color;
      color:#FFF;
    }
  }
}

/* cookie alert */
.alert{
  margin-bottom:0;
}
.alert-success{
  background-color:white;
  border:none;
  strong{
    color:$primary-color;
  }
  a{
    color:$secondary-color;
  }
}